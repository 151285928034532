.fh-block__accordion {
    .fh-block__accordion--item {
        &__tab {
            cursor: pointer;
        }

        &__content {
            height: 0;
            overflow: hidden;
            transition: none !important;
        }
    }
}