@mixin breakpoint($size, $constraint: min) {
	@if $constraint == max {
		@media screen and (max-width: $size) {
			@content;
		}
	}

	@if $constraint == min {
		@media screen and (min-width: $size) {
			@content;
		}
	}
}

@import 'blocks/accordion/frontend';
@import 'blocks/button/frontend';
@import 'blocks/content-with-image/frontend';
@import 'blocks/tabs/frontend';