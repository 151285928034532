.fh-block__tabs {
    max-width: calc(100vw - 4rem);

    &--buttons {
        @media screen and (max-width: 767px) {
            flex-flow: row nowrap;
            overflow: scroll;
            white-space: nowrap;
        }

        button {
            cursor: pointer;
        }
    }

    .fh-block__tabs--item {
        display: none;

        &.active {
            display: block;
        }
    }
}