.fh-content-with-image {
    &__int {
        align-items: center;

        @include breakpoint(768px) {
            display: grid;
            grid-gap: 6rem;
            grid-template-columns: 6fr 4fr;
        }
    }

    &.is-style-rightAligned &__int {
        @include breakpoint(768px) {
            grid-template-columns: 4fr 6fr;
        }
    }

    &__wrap {
        grid-row: 1;
        padding: 0 0 100%;
        position: relative;

        @include breakpoint(768px) {
            grid-column: 1;
        }
    }

    &.is-style-rightAligned &__wrap {
        grid-row: 2;

        @include breakpoint(768px) {
            grid-row: 1;
            grid-column: 2;
        }
    }

    &__image {
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    &__content {
        grid-row: 2;
        
        @include breakpoint(768px) {
            grid-row: 1;
            grid-column: 2;
        }
    }

    &.is-style-rightAligned &__content {
        grid-row: 1;

        @include breakpoint(768px) {
            grid-column: 1;
        }
    }
}